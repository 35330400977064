.tab-bar .tab-verified .text-blue-600 {
    color: #22c55e
}

.tab-bar .tab-pending .text-blue-600 {
    color: #eab308
}

.tab-bar .tab-rejected .text-blue-600 {
    color: #ef4444
}

/* .card-small .w-fit {
    width: 100%;
}

.card-small .w-fit .absolute {
    width: fit-content;
} */